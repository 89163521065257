<template>
  <div class="m-3">
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('flightPlan.beforeEdit') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="$emit('close', {plan:plan})" href="javascript:void(0)"
           class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>
    <div class="row preview mt-3">
      <div class="col-md-6">
        <label>{{ $t('flightPlan.selectRoutePlan') }}</label>
        <select class="form-control" v-model="selectedRoutePlan"
                v-on:change="changeRoutePlan()">
          <option :value="null">{{ $t('auto-complete.please-choose') }}</option>
          <option v-for="routePlan in myRoutePlans" :key="routePlan.id" :value="routePlan">
            {{ routePlan.name }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label>{{ $t('routePlan.selectPlane') }}</label>
        <select class="form-control" v-model="selectedPlane"
                v-on:change="changePlane()">
          <option :value="null">{{ $t('auto-complete.please-choose') }}</option>
          <option v-for="plane in myPlanes" :key="plane.id" :value="plane">
            {{ plane.registrationNumber }} ({{ plane.model }})
          </option>
        </select>
      </div>
    </div>
    <div class="col-12 text-right mt-3">
      <a v-on:click="$emit('close',{plan:plan})" href="javascript:void(0)"
         class="btn btn-secondary text-center">
        <font-awesome-icon class="m-auto" icon="check-circle"/>
        {{ $t('routePlan.unitOk') }}
      </a>
    </div>
  </div>
</template>

<script>
import {HU_TO_EN} from "@/plugins/utils";

export default {
  name: "BeforeEditModal",
  props: ['plan'],
  data() {
    return {
			/** @type {Plane[]} */
      myPlanes: [],
			/** @type {RoutePlan[]} */
      myRoutePlans: [],
			/** @type {Plane|null} */
      selectedPlane: null,
			/** @type {RoutePlan|null} */
      selectedRoutePlan: null,
    };
  },
  async mounted() {
		const ups = await this.$rest.loadMyUserPlanes() || [];
		this.myPlanes = ups.map(up => up.plane);

		this.myRoutePlans = await this.$rest.getRoutePlansForUser() || [];
  },
  methods: {
		/**
		 * @param {SpeedUnitType} unit
		 * @param {number} value
		 * @return {{
		 *   value: number,
		 *   unit: string
		 * }}
		 * */
		getFlightPlanSpeedFromRealUnit(unit, value){

			let speedUnits = {
				'kmh': 'K',
				"kt": "N",
				"mach": "M",
			}

			const speedUnit = speedUnits[unit];
			if(speedUnit){
				return {
					value,
					unit: speedUnit
				};
			}

			return {
				value: this.$units.convert(value, unit, 'kt'),
				unit: 'N'
			};
		},


		/**
		 * Converts a speed from a real unit to a flight plan unit
		 * @param {DistanceUnitType} unit
		 * @param {number} value
		 * @return {{
		 *   value: number,
		 *   unit: string
		 * }}
		 * */
		getFlightPlanAltitudeFromRealUnit(unit, value){
			let altitudeUnits = {
				"ft": "A",
				'fl': 'F',
				"m": "M",
				"": "VFR",
			}

			const altUnit = altitudeUnits[unit];
			if(altUnit){
				return {
					value,
					unit: altUnit
				};
			}

			return {
				value: this.$units.convert(value, unit, 'ft'),
				unit: 'A'
			};
		},

    changePlane() {
      this.plan.registrationNumber = this.selectedPlane.registrationNumber;
      this.plan.plane = this.selectedPlane;
      this.plan.typeOfAircraft = this.selectedPlane.planeModel
      this.plan.turbulenceCategory = this.selectedPlane.turbulenceCategory
      this.plan.equipment = this.selectedPlane.icaoEquipments.join('') + '/' + this.selectedPlane.icaoSurveillances.join('');
      this.plan.icaoEquipments = this.selectedPlane.icaoEquipments;
      this.plan.icaoSurveillances = this.selectedPlane.icaoSurveillances;

			let flightPlanLevel = this.getFlightPlanAltitudeFromRealUnit(this.selectedPlane.altitudeUnit, this.selectedPlane.defaultAltitude);
      this.plan.level = String(flightPlanLevel.value);
      this.plan.levelUnit = flightPlanLevel.unit;

			let flightPlanSpeed = this.getFlightPlanSpeedFromRealUnit(this.selectedPlane.speedUnit, this.selectedPlane.defaultSpeed);
      this.plan.cruisingSpeed = String(flightPlanSpeed.value);
      this.plan.speedUnit = flightPlanSpeed.unit;
      this.plan.aircraftColor = this.selectedPlane.colorAndNotation;
      this.selectedPlane.emergencyRadios.forEach(radio =>{
        this.plan.emergencyRadios.push(radio);
      });
      this.selectedPlane.survivalEquipments.forEach(radio =>{
        this.plan.survivalEquipments.push(radio);
      });
      this.selectedPlane.jackets.forEach(radio =>{
        this.plan.jackets.push(radio);
      });
      this.plan.otherInformation.OPR = this.selectedPlane.operator;
    },
    changeRoutePlan() {
      this.plan.registrationNumber = this.selectedRoutePlan.planeRegistrationNumber;
      this.plan.routePlan = this.selectedRoutePlan
      const plan = this.myPlanes.find(plane => plane.registrationNumber === this.selectedRoutePlan.planeRegistrationNumber);
      if (plan) {
        this.selectedPlane = plan;
        this.changePlane();
      }
      this.plan.departure = this.selectedRoutePlan.startElementTitle
      this.plan.destination = this.selectedRoutePlan.routePlanElements[this.selectedRoutePlan.routePlanElements.length - 1].title
      if (this.selectedRoutePlan.alternates.length > 0) {
        this.plan.altnOne = this.selectedRoutePlan.alternates[0].title
      }
      if (this.selectedRoutePlan.alternates.length > 1) {
        this.plan.altnTwo = this.selectedRoutePlan.alternates[0].title
      }
      this.plan.pilotInCommand = (this.selectedRoutePlan.pilotInCommand?this.selectedRoutePlan.pilotInCommand:this.plan.pilotInCommand);
      this.plan.totalEET = this.calcTotalEET();
			let flightPlanSpeed = this.getFlightPlanSpeedFromRealUnit(this.selectedRoutePlan.speedUnit, this.selectedRoutePlan.defaultSpeed);
			this.plan.cruisingSpeed = String(flightPlanSpeed.value);
			this.plan.speedUnit = flightPlanSpeed.unit;
      this.plan.endurance = this.calcEndurance();
      this.plan.routeElements = this.generateRoute();

			console.log(JSON.parse(JSON.stringify(this.plan)));
    },
    calcEndurance(){
      let total = 0;
      total += this.calcTotalElementTotalTime();
      total += (this.selectedRoutePlan.finalReserve/100); //finalReserveTime
      total += (this.calcTotalElementTotalTime() * this.selectedRoutePlan.contingency / 100); //contingencyTime
      total += (this.selectedRoutePlan.navReserve <1?0: this.selectedRoutePlan.navReserve/this.selectedRoutePlan.avgConsumption); //navreserveTime
      total += (this.selectedRoutePlan.extraFuel <1?0: this.selectedRoutePlan.extraFuel/this.selectedRoutePlan.avgConsumption); //extraFuelTime
      return this.$options.filters.formatHourWithoutUnit(total);
    },
    calcTotalElementTotalTime: function () {
      let total = 0;
      this.selectedRoutePlan.routePlanElements.forEach(element => {
        total += element.flightTime;
      });
      return total;
    },
    calcTotalEET() {
      let total = this.calcTotalElementTotalTime();
      return this.$options.filters.formatHourWithoutUnit(total);
    },
    generateRoute(){
      let result = [];
      for (let i = 0; i < this.selectedRoutePlan.routePlanElements.length-1; i++) {
        const element = this.selectedRoutePlan.routePlanElements[i];
        let tmp = "";
        tmp += HU_TO_EN(element.title.substring(0,11).toUpperCase());
        result.push(tmp);
      }
      return result;
    }
  }
}
</script>

<style scoped>

</style>
