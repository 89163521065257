<template>
  <div class="p-3">
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('flightPlan.route') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="$emit('close', {routeElements:elementList})" href="javascript:void(0)"
           class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>
    <div class="row preview mt-3" style="height:400px; overflow-y:auto">
      <div class="col-12 form-group">
        <label>{{ $t('flightPlan.routeElement') }}: *</label>
        <div class="row align-items-center">
          <div class="col-12">
            <vue-typeahead-bootstrap
              v-model="elementTitle"
              :data="mapObjects"
              @hit="addElement($event)"
              :serializer="item => waypointName(item)"
            />
          </div>
<!--          <div class="col-4">-->
<!--            <button :disabled="element ==null" class="btn btn-sm btn-primary" v-on:click="addElement">-->
<!--              {{ $t('flightPlan.add') }}-->
<!--            </button>-->
<!--          </div>-->
        </div>
      </div>
      <div class="col-12">
        <draggable
          ghost-class="ghost"
          class="list-group"
          v-model="elementList"
        >
          <li class="list-group-item d-flex justify-content-between align-items-center" style="cursor:move"
              v-for="(element, index) in elementList" :key="element">
            {{ element }}

            <span class="badge">
             <button
               class="ml-3 btn btn-sm "
               :class="{'btn-warning':flightIndex == index,'btn-primary':flightIndex != index }"
               v-if="flightRuleRoute.length>0"
               v-on:click="setFlightRule(index)">
               {{ flightRuleRoute }}
             </button>
             <a href="javascript:void(0)" class="ml-3" v-on:click="deleteElement(index)"> <font-awesome-icon
               class="m-auto" icon="trash"/></a>
            </span>
          </li>
        </draggable>
      </div>
    </div>
    <div class="col-12 text-right mt-3">
      <a v-on:click="$emit('close',{routeElements:elementList})" href="javascript:void(0)"
         class="btn btn-secondary text-center">
        <font-awesome-icon class="m-auto" icon="check-circle"/>
        {{ $t('routePlan.unitOk') }}
      </a>
    </div>
  </div>
</template>

<script>
import {LOCAL_STORAGE_SERVICE, ROUTE_PLAN_CALCULATOR, STORAGE_KEY} from "@/plugins/utils";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import draggable from 'vuedraggable'

export default {
  name: "RouteChooserModal",
  props: ['routeElements', 'flightRuleRoute'],
  components: {
    VueTypeaheadBootstrap, draggable
  },
  data() {
    return {
      mapObjects: [],
      element: null,
      elementTitle: "",
      elementList: [],
      flightIndex: null
    }
  },
  async mounted() {
		this.mapObjects = await this.$rest.getAllObjects();
    this.elementList = this.routeElements;
    this.checkVFRIFR();
  },
  methods: {
    waypointName(item){
      if (item.type === 'CITY' || item.type === 'NAVIGATION_POINT') {
        return item.title;
      }
      return item.title +' ('+item.name+')';
    },
    checkVFRIFR() {
      if (this.flightRuleRoute) {
        const i = this.elementList.findIndex(o => o.includes(this.flightRuleRoute));
        if (i > -1) {
          this.flightIndex = i;
        }
        this.setFlightRule(i);
      }
    },
    setFlightRule(i) {
      if (this.flightIndex) {
        let elementListElement = this.elementList[this.flightIndex];
        this.elementList[this.flightIndex] = elementListElement.substring(0, elementListElement.length - 4);

      }
      this.elementList[i] = this.elementList[i] + "/" + this.flightRuleRoute;
      this.flightIndex = i;
    },
    async deleteElement(i) {
      if (await this.$confirm(this.$t("flightPlan.confirmDelete"))) {
        this.elementList.splice(i, 1);
      }
    },
    addElement(element) {
      this.element = element;
      let tmp = "";
      tmp += this.element.title.substring(0, 11).toUpperCase();
      // tmp += "/";
      // tmp += ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LAT_11(this.element.latitude) + ROUTE_PLAN_CALCULATOR.GET_DMS_TO_LNG_11(this.element.longitude);
      this.elementList.push(tmp);
      this.elementTitle = "";
    }
  }
}
</script>

<style scoped>
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
