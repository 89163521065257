<template>
	<div ref="section">
		<div class="row preview mt-3">
			<div class="col-md-6 form-group">
				<label>{{ $t('routePlan.planeRegistrationNumber') }}: *</label>
				<input id="registrationNumber" type="text" class="form-control"
					v-on:keyup="plan.registrationNumber = plan.registrationNumber.toUpperCase()"
					v-model="plan.registrationNumber" />
				<ComponentMessage :msgs="msgs" forComponent="registrationNumber" />
			</div>
			<div class="col-md-3 form-group">
				<label>{{ $t('flightPlan.flightRules') }}</label>
				<select id="flightRule" class="form-control" v-model="plan.flightRule" v-on:change="changeFlightRules()">
					<option :value="null">{{ $t('auto-complete.please-choose') }}</option>
					<option v-for="rule in flightRules" :value="rule.code" :key="rule.code">
						{{ rule.code }} - {{ rule.description }}
					</option>
				</select>
				<ComponentMessage :msgs="msgs" forComponent="flightRule" />
			</div>
			<div class="col-md-3 form-group">
				<label>{{ $t('flightPlan.typeOfFlight') }}</label>
				<select id="typeOfFlight" class="form-control" v-model="plan.typeOfFlight">
					<option :value="null">{{ $t('auto-complete.please-choose') }}</option>
					<option v-for="typeOf in typesOfFlight" :value="typeOf.code" :key="typeOf.code">
						{{ typeOf.code }} - {{ typeOf.description }}
					</option>
				</select>
				<ComponentMessage :msgs="msgs" forComponent="typeOfFlight" />
			</div>

			<div class="col-md-4 form-group">
				<label>{{ $t('flightPlan.number') }}: *</label>
				<input id="number" type="text" class="form-control" maxlength="4" disabled v-model="plan.number" />
				<ComponentMessage :msgs="msgs" forComponent="number" />
			</div>
			<div class="col-md-4 form-group">
				<label>{{ $t('flightPlan.typeOfAircraft') }}: *</label>
				<input id="typeOfAircraft" type="text" class="form-control" maxlength="4" v-on:keyup="checkTypeOfAirCraft"
					v-model="plan.typeOfAircraft" />

				<ComponentMessage :msgs="msgs" forComponent="typeOfAircraft" />
			</div>
			<div class="col-md-4 form-group">
				<label>{{ $t('flightPlan.turbulenceCategory') }}: *</label>
				<select id="turbulenceCategory" class="form-control" v-model="plan.turbulenceCategory">
					<option :value="null">{{ $t('auto-complete.please-choose') }}</option>
					<option v-for="cat in turbulenceCategories" :value="cat.code" :key="cat.code">
						{{ cat.code }} - {{ cat.description }}
					</option>
				</select>
				<ComponentMessage :msgs="msgs" forComponent="turbulenceCategory" />
			</div>
			<div class="col-md-12 form-group">
				<label>{{ $t('flightPlan.equipment') }}: *</label>
				<div class="input-group">
					<MultiSelect id="icaoEquipments" style="width: 33%" v-model="selectedIcaoEquipments" :options="icaoEquipments"
						optionLabel="value" @change="refreshEquipment()" />
					<MultiSelect id="icaoSurveillances" style="width: 33%" v-model="selectedIcaoSurveillances"
						:options="icaoSurveillances" optionLabel="value" @change="refreshEquipment()" />
					<div class="input-group-append">
						<div class="input-group-text">{{ plan.equipments }}</div>
					</div>
				</div>
				<ComponentMessage class="float-left" :msgs="msgs" style="width: 33%" forComponent="icaoEquipments" />
				<ComponentMessage class="float-left" :msgs="msgs" style="width: 33%" forComponent="icaoSurveillances" />
			</div>


			<div class="col-md-4 form-group">
				<label>{{ $t('flightPlan.departure') }}: *</label>
				<vue-typeahead-bootstrap id="departure" @hit="checkAltn('departure', $event)" v-model="plan.departure"
					:data="airports" :serializer="item => item.code" />
				<ComponentMessage :msgs="msgs" forComponent="departure" />
			</div>
			<div class="col-md-4 form-group">
				<label>{{ $t('flightPlan.startTime') }}: *</label>
				<div class="input-group">
					<input id="startTime" type="time" class="form-control" v-model="plan.startTime" maxlength="4"
						placeholder="HHMM" />
					<div class="input-group-append">
						<div class="input-group-text">UTC</div>
					</div>
				</div>
				<ComponentMessage :msgs="msgs" forComponent="startTime" />
			</div>
			<div class="col-md-4 form-group">
				<label>{{ $t('flightPlan.startTime') }}: *</label>
				<div class="input-group">
					<input id="startDate" type="date" class="form-control" v-model="startDate" v-on:change="generateOtherInfo" />
					<div class="input-group-append">
						<div class="input-group-text">UTC</div>
					</div>
				</div>
				<ComponentMessage :msgs="msgs" forComponent="startDate" />
			</div>

			<div class="col-md-6 form-group">
				<label>{{ $t('flightPlan.cruisingSpeed') }}: *</label>
				<div class="input-group">
					<div class="input-group-prepend">
						<select class="form-control" v-model="plan.speedUnit">
							<option value="N">{{ $t('unit.kt') }}</option>
							<option value="K">{{ $t('unit.kmh') }}</option>
						</select>
					</div>
					<input id="cruisingSpeed" type="number" class="form-control" v-model="plan.cruisingSpeed" maxlength="4"
						max="9999" />
					<div class="input-group-append">
						<div class="input-group-text">{{ plan.speedUnit }}{{ plan.cruisingSpeed | cruisingSpeed }}</div>
					</div>
				</div>
				<ComponentMessage :msgs="msgs" forComponent="cruisingSpeed" />
			</div>
			<div class="col-md-6 form-group">
				<label>{{ $t('flightPlan.level') }}: *</label>
				<div class="input-group">
					<div class="input-group-prepend">
						<select class="form-control" v-model="plan.levelUnit" v-on:change="changeLevelUnit">
							<option value="A">{{ $t('units.feet') }}</option>
							<option value="F">{{ $t('unit.fl') }}</option>
							<option value="M">{{ $t('unit.meter') }}</option>
							<option value="VFR">VFR</option>
						</select>
					</div>
					<input id="level" type="number" class="form-control" v-model="plan.level"
						:disabled="plan.level === 0 && plan.levelUnit === 'VFR'" />
					<div class="input-group-append">
						<div class="input-group-text">{{ plan.levelUnit }}{{ plan.level | flightLevel(plan.levelUnit) }}</div>
					</div>
				</div>
				<ComponentMessage :msgs="msgs" forComponent="level" />
			</div>
			<div class="col-md-12 form-group">
				<label class="w-100">
					<span class="float-left">{{ $t('flightPlan.route') }}: *</span>
					<span class="float-right">
						<a @click="routeEdit = !routeEdit" class="pt-2 mr-2" href="javascript:void(0)">
							<font-awesome-icon icon="pencil-alt" v-if="!routeEdit" />
							<font-awesome-icon icon="times" v-if="routeEdit" />
						</a>
						<a @click="showRoute" class="pt-2" href="javascript:void(0)">
							<font-awesome-icon icon="cog" />
						</a>
					</span>
				</label>
				<textarea id="route" v-model="plan.route" class="form-control" rows="5" :readonly="!routeEdit"
					v-on:keyup="updateRouteElements"></textarea>
				<ComponentMessage :msgs="msgs" forComponent="route" />
			</div>

		</div>
		<div class="row preview mt-3">
			<div class="col-md-3 form-group">
				<label>{{ $t('flightPlan.destination') }}: *</label>
				<vue-typeahead-bootstrap id="destination" @hit="checkAltn('destination', $event)" v-model="plan.destination"
					:data="airports" :serializer="item => item.code" />

				<ComponentMessage :msgs="msgs" forComponent="destination" />
			</div>
			<div class="col-md-3  form-group">
				<label>{{ $t('flightPlan.totalEET') }}: *</label>
				<div class="input-group">
					<input id="totalEET" type="text" class="form-control" v-model="plan.totalEET" maxlength="4"
						placeholder="HHMM" />
				</div>

				<ComponentMessage :msgs="msgs" forComponent="totalEET" />
			</div>
			<div class="col-md-3 form-group">
				<label>{{ $t('flightPlan.altnOne') }}:</label>
				<vue-typeahead-bootstrap id="altnOne" @hit="checkAltn(0, $event)" v-model="plan.altnOne" :data="airports"
					:serializer="item => item.code" />

				<ComponentMessage :msgs="msgs" forComponent="altnOne" />
			</div>
			<div class="col-md-3  form-group">
				<label>{{ $t('flightPlan.altnTwo') }}:</label>
				<vue-typeahead-bootstrap @hit="checkAltn(1, $event)" v-model="plan.altnTwo" :data="airports"
					:serializer="item => item.code" />
			</div>
			<div class="col-md-12  form-group">
				<label class="w-100">
					<span class="float-left"> {{ $t('flightPlan.other') }}: *</span>
					<span class="float-right">
						<a @click="otherEdit = !otherEdit" class="pt-2 mr-2" href="javascript:void(0)">
							<font-awesome-icon icon="pencil-alt" v-if="!otherEdit" />
							<font-awesome-icon icon="times" v-if="otherEdit" />
						</a>
						<a @click="showOther" class="pt-2" href="javascript:void(0)">
							<font-awesome-icon icon="cog" />
						</a>
					</span>
				</label>
				<textarea id="otherInformation" v-model="plan.other" class="form-control" rows="5" :readonly="!otherEdit"
					v-on:keyup="updateOtherInfo"></textarea>

				<ComponentMessage :msgs="msgs" forComponent="otherInformation" />
			</div>

			<div class="col-md-4  form-group">
				<label>{{ $t('flightPlan.endurance') }}: *</label>
				<div class="input-group">
					<input id="endurance" type="text" class="form-control" v-model="plan.endurance" maxlength="4"
						placeholder="HHMM" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="endurance" />
			</div>

			<div class="col-md-4  form-group">
				<label>{{ $t('flightPlan.personsOnBoard') }}: * </label>
				<div class="input-group">
					<input id="personsOnBoard" type="text" class="form-control" :value="plan.personsOnBoard" @input="e => plan.personsOnBoard = Number(e.target.value)" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="personsOnBoard" />
			</div>
			<div class="col-md-4 form-group"></div>

			<div class="col-sm-4 form-group">
				<label for="speedUnit">{{ $t('planes.emergencyRadio') }}: </label>
				<MultiSelect id="emergencyRadios" v-model="plan.emergencyRadios" :options="emergencyRadios"
					placeholder="Select emergency radio" style="width: 100%" />

				<ComponentMessage :msgs="msgs" forComponent="emergencyRadios" />
			</div>

			<div class="col-sm-4 form-group">
				<label for="speedUnit">{{ $t('planes.survivalEquipment') }}: </label>
				<MultiSelect id="survivalEquipments" v-model="plan.survivalEquipments" :options="survivalEquipments"
					placeholder="Select survival equipment" style="width: 100%" />
				<ComponentMessage :msgs="msgs" forComponent="survivalEquipments" />
			</div>

			<div class="col-sm-4 form-group">
				<label for="speedUnit">{{ $t('planes.jackets') }}: </label>
				<MultiSelect id="jackets" v-model="plan.jackets" :options="jackets" placeholder="Select Jackets"
					style="width: 100%" />
				<ComponentMessage :msgs="msgs" forComponent="jackets" />
			</div>

			<div class="col-md-12 form-group">
				<label>{{ $t('flightPlan.aircraftColor') }}: *</label>
				<div class="input-group">
					<input id="aircraftColor" type="text" class="form-control" v-model="plan.aircraftColor" />
				</div>

				<ComponentMessage :msgs="msgs" forComponent="aircraftColor" />
			</div>
			<div class="col-md-12 form-group">
				<label>{{ $t('flightPlan.remarks') }}:</label>
				<div class="input-group">
					<input id="remarks" type="text" class="form-control" v-model="plan.remarks" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="remarks" />
			</div>
			<div class="col-md-6 form-group">
				<label>{{ $t('flightPlan.pilotInCommand') }}: *</label>
				<div class="input-group">
					<input id="pilotInCommand" type="text" class="form-control" v-model="plan.pilotInCommand" />
				</div>

				<ComponentMessage :msgs="msgs" forComponent="pilotInCommand" />
			</div>
			<div class="col-md-6 form-group">
				<label>{{ $t('flightPlan.pilotInCommandPhone') }}: *</label>
				<div class="input-group">
					<input id="pilotInCommandPhone" type="text" class="form-control" v-model="plan.pilotInCommandPhone" />
				</div>
				<ComponentMessage :msgs="msgs" forComponent="pilotInCommandPhone" />
			</div>

			<!--      <div class="col-md-6">-->
			<!--        <pre>-->
			<!--        {{ plan }}-->
			<!--          </pre>-->
			<!--      </div>-->
		</div>
		<div class="row plane-row" style="text-align: center">
			<div class="col-12">
				<div class="wizard-footer-top">
					<button @click="save" class="btn btn-primary">
						<font-awesome-icon icon="check" />
						{{ $t('planes.save') }}
					</button>
					<button @click="$emit('cancel')" class="btn btn-secondary ml-3">
						<font-awesome-icon icon="window-close" />
						{{ $t('planes.cancel') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import { HU_TO_EN, LOCAL_STORAGE_SERVICE, STORAGE_KEY } from "@/plugins/utils";
import MultiSelect from 'primevue/multiselect';
import OtherChooserModal from "@/components/flightPlan/flightPlanModal/OtherChooserModal";
import RouteChooserModal from "@/components/flightPlan/flightPlanModal/RouteChooserModal";
import moment from "moment";

export default {
	name: "FlightPlanForm",
	props: ['plan', 'msgs', 'app'],
	emits: ['success', 'cancel', 'successUpdate'],
	components: {
		VueTypeaheadBootstrap, MultiSelect
	},
	data() {
		return {
			startDate: moment().format('YYYY-MM-DD'),
			routeEdit: true,
			otherEdit: true,
			myPlanes: [],
			selectedPlane: null,
			flightRuleRoute: "",
			flightRules: [
				{ code: 'V', description: 'VFR' },
				{ code: 'I', description: 'IFR' },
				{ code: 'Y', description: 'IFR/VFR' },
				{ code: 'Z', description: 'VFR/IFR' },
			],
			turbulenceCategories: [
				{ code: 'L', description: 'LIGHT (7 000 kg or less)' },
				{ code: 'M', description: 'MEDIUM (7001 - 136 000 kg)' },
				{ code: 'H', description: 'HEAVY (136 001 kg or more)' },
				{ code: 'J', description: 'SUPER HEAVY (A380-800)' },
			],
			typesOfFlight: [
				{ code: 'S', description: 'Scheduled' },
				{ code: 'N', description: 'Non-Scheduled' },
				{ code: 'M', description: 'Military' },
				{ code: 'X', description: 'Other' },
				{ code: 'G', description: 'General Aviation' },
			],
			cruisingSpeed: "",
			airports: [],
			selectedIcaoEquipments: [],
			selectedIcaoSurveillances: [],
			emergencyRadios: [
				'UHF',
				'VHF',
				'ELT',
			],
			survivalEquipments: [
				'Polar',
				'Desert',
				'Maritime',
				'Jungle',
			],
			jackets: [
				'Light',
				'Floures',
				'UHF',
				'VHF',
			],
			icaoSurveillances: [
				{ code: 'N', value: "N-NIL" },
				{ code: 'A', value: "A-Mode A" },
				{ code: 'B1', value: "B1-ADS-B, Dedicated 1090 Out" },
				{ code: 'B2', value: "B2-ADS-B, Dedicated 1090 Out+In" },
				{ code: 'C', value: "C-Modes A and C" },
				{ code: 'D1', value: "D1-ADS-C, FANS" },
				{ code: 'E', value: "E-Mode S, ID, Alt, Squitter" },
				{ code: 'G1', value: "G1-ADS-C, ATN" },
				{ code: 'H', value: "H-Mode S, ID, Alt, Enhanced Surv" },
				{ code: 'I', value: "I-Mode S, ID no Alt" },
				{ code: 'L', value: "L-Mode S, ID, Alt, Squitter+Enh Surv" },
				{ code: 'P', value: "P-Mode S, Alt no ID" },
				{ code: 'S', value: "S-Mode S, ID and Alt" },
				{ code: 'U1', value: "U1-ADS-B, UAT Out" },
				{ code: 'U2', value: "U2-ADS-B, UAT Out+In" },
				{ code: 'V1', value: "V1-ADS-B, VDL Mode 4 Out" },
				{ code: 'V2', value: "V2-ADS-B, VDL Mode 4 Out+In" },
				{ code: 'X', value: "X-Mode S, no ID no Alt" },
			],
			icaoEquipments: [
				{ code: 'N', value: 'N-NIL' },
				{ code: 'A', value: 'A-GBAS Landing Sys' },
				{ code: 'B', value: 'B-LPV (APV with SBAS)' },
				{ code: 'C', value: 'C-LORAN C' },
				{ code: 'D', value: 'D-DME' },
				{ code: 'E1', value: 'E1-FMC WPR ACARS' },
				{ code: 'E2', value: 'E2-D-FIS ACARS' },
				{ code: 'E3', value: 'E3-PDC ACARS' },
				{ code: 'F', value: 'F-ADF' },
				{ code: 'G', value: 'G-GNSS' },
				{ code: 'H', value: 'H-HF RTF' },
				{ code: 'I', value: 'I-Inertial Nav.' },
				{ code: 'J1', value: 'J1-CPDLC ATN DL Mode 2' },
				{ code: 'J2', value: 'J2-CPDLC FANS 1/A HFDL' },
				{ code: 'J3', value: 'J3-CPDLC FANS 1/A VDL Mode A' },
				{ code: 'J4', value: 'J4-CPDLC FANS 1/A VDL Mode 2' },
				{ code: 'J5', value: 'J5-CPDLC FANS 1/A (INMARSAT)' },
				{ code: 'J6', value: 'J6-CPDLC FANS 1/A (MTSAT)' },
				{ code: 'J7', value: 'J7-CPDLC FANS 1/A (Iridium)' },
				{ code: 'K', value: 'K-MLS' },
				{ code: 'L', value: 'L-ILS' },
				{ code: 'M1', value: 'M1-ATC RTF (INMARSAT)' },
				{ code: 'M2', value: 'M2-ATC RTF (MTSAT)' },
				{ code: 'M3', value: 'M3-ATC RTF (Iridium)' },
				{ code: 'O', value: 'O-VOR' },
				{ code: 'P1', value: 'P1-CPDLC RCP 400' },
				{ code: 'P2', value: 'P2-CPDLC RCP 240' },
				{ code: 'P3', value: 'P3-SATVOICE RCP 400' },
				{ code: 'R', value: 'R-PBN Approved' },
				{ code: 'S', value: 'S-(VOR, VHF RTF, ILS)' },
				{ code: 'T', value: 'T-TACAN' },
				{ code: 'U', value: 'U-UHF RTF' },
				{ code: 'V', value: 'V-VHF RTF' },
				{ code: 'W', value: 'W-RVSM' },
				{ code: 'X', value: 'X-MNPS or NAT HLA Approved' },
				{ code: 'Y', value: 'Y-VHF 8.33 kHz spacing' },
				{ code: 'Z', value: 'Z-Other' },
			],
		}
	},
	mounted() {
		this.generateRoute();
		this.generateOtherInfo()
		if (this.plan.plane.icaoEquipments) {
			this.selectedIcaoEquipments = this.icaoEquipments.filter(ieo => this.plan.plane.icaoEquipments.includes(ieo.code));
			this.selectedIcaoSurveillances = this.icaoSurveillances.filter(iso => this.plan.plane.icaoSurveillances.includes(iso.code));
		}
		if (this.plan.icaoEquipments) {
			this.selectedIcaoEquipments = this.icaoEquipments.filter(ieo => this.plan.icaoEquipments.includes(ieo.code));
			this.selectedIcaoSurveillances = this.icaoSurveillances.filter(iso => this.plan.icaoSurveillances.includes(iso.code));
		}
		this.refreshEquipment();

	},
	methods: {
		showRoute() {
			this.$modal.show(RouteChooserModal, {
				routeElements: this.plan.routeElements,
				flightRuleRoute: this.flightRuleRoute
			}, {
				height: 'auto',
				width: '900px',
				clickToClose: false,
				adaptive: true,
			}, {
				'before-close': this.beforeCloseRouteChooserModal
			});
		},
		beforeCloseRouteChooserModal(event) {
			this.plan.routeElements = event.params.routeElements;
			this.generateRoute();
		},
		showOther() {
			this.$modal.show(OtherChooserModal, {
				otherInformation: this.plan.otherInformation
			}, {
				height: 'auto',
				width: '900px',
				clickToClose: false,
				adaptive: true,
			}, {
				'before-close': this.beforeCloseOtherModal
			});
		},
		// updatePhone(){
		//   if(!this.plan.otherInformation.RMK){
		//     this.plan.otherInformation.RMK = "PHONE " + this.plan.pilotInCommandPhone.replaceAll('+', '00').replaceAll(' ', '');
		//   }
		//   if(this.plan.otherInformation.RMK && !this.plan.otherInformation.RMK.includes('PHONE')){
		//     this.plan.otherInformation.RMK += " PHONE " +this.plan.pilotInCommandPhone.replaceAll('+', '00').replaceAll(' ', '');
		//   }else{
		//     const a = this.plan.otherInformation.RMK.split('PHONE');
		//     console.log(a);
		//   }
		//   console.log(this.plan.otherInformation);
		// },
		updateOtherInfo() {
			let separator = /[a-zA-Z]{3,}\//g
			const planOther = this.plan.other;//.replaceAll(' ', '');
			let values = planOther.split(separator);
			const keys = planOther.match(separator);
			if (keys.length < values.length) {
				values.splice(0, 1);
			}
			this.plan.otherInformation = {};
			for (let i = 0; i < keys.length; i++) {
				this.plan.otherInformation[keys[i].replace('/', '')] = values[i].toUpperCase();
			}
		},
		generateOtherInfo: function () {
			this.plan.other = "";
			this.plan.otherInformation.DOF = moment(this.startDate, 'YYYY-MM-DD').format('YYMMDD');
			Object.keys(this.plan.otherInformation).forEach(infoKey => {
				this.plan.other += infoKey + "/" + this.plan.otherInformation[infoKey].toUpperCase() + " ";
			});
		},
		beforeCloseOtherModal(event) {
			this.plan.otherInformation = event.params.otherInformation;
			this.generateOtherInfo();
		},
		refreshEquipment() {
			this.plan.equipments = this.selectedIcaoEquipments.map(ieo => ieo.code).join("") + "/" + this.selectedIcaoSurveillances.map(iso => iso.code).join("")
			this.plan.icaoEquipments = this.selectedIcaoEquipments.map(ieo => ieo.code);
			this.plan.icaoSurveillances = this.selectedIcaoSurveillances.map(iso => iso.code);
		},
		changeLevelUnit() {
			if (this.plan.levelUnit == 'VFR') {
				this.plan.level = '0';
			}
		},
		checkTypeOfAirCraft() {
			this.plan.typeOfAircraft = this.plan.typeOfAircraft.toUpperCase();
			if (this.plan.typeOfAircraft.toLowerCase() == 'zzzz') {
				this.plan.otherInformation['TYP'] = "";
			} else {
				delete this.plan.otherInformation['TYP'];
			}
			this.generateOtherInfo();
		},
		generateRoute() {
			this.plan.route = "";
			this.plan.routeElements.forEach(element => {
				this.plan.route += HU_TO_EN(element.substring(0, 11).toUpperCase()) + " ";
			});

		},
		updateRouteElements() {
			this.plan.routeElements = [];
			const routeElements = this.plan.route.split(' ');
			routeElements.forEach(element => {
				this.plan.routeElements.push(HU_TO_EN(element.substring(0, 11).toUpperCase()));
			});
		},
		changeFlightRules() {
			if (this.plan.flightRule.toLowerCase() == 'y') {
				this.flightRuleRoute = 'VFR';
			} else if (this.plan.flightRule.toLowerCase() == 'z') {
				this.flightRuleRoute = 'IFR';
			} else {
				this.flightRuleRoute = '';
			}
			this.generateRoute();
		},
		async save() {
			try {
				this.validation();
				this.plan.startTime = this.plan.startTime.replace(':', '');
				this.plan.endurance = this.plan.endurance.replace(':', '');
				this.plan.totalEET = this.plan.totalEET.replace(':', '');
				if (!this.plan.plane || !this.plan.plane.id) {
					this.plan.plane = null;
				}
				if (!this.plan.routePlan || !this.plan.routePlan.id) {
					this.plan.routePlan = null;
				}
				if (this.plan.id && this.plan.id > 0) {
					const result = await this.$rest.updateFlightPlan(this.plan);
					if(!result){
						this.$refs.section.scrollTo(0, 0);
						return;
					}
					this.$emit('successUpdate', result);
					this.planStartTimeRecover();
				} else {
					console.log(JSON.parse(JSON.stringify(this.plan)));
					const result = await this.$rest.addFlightPlan(this.plan);
					if (!result) {
						this.$refs.section.scrollTo(0, 0);
						return;
					}
					this.plan.id = result.id;
					this.$emit('success', result);
					this.planStartTimeRecover();
				}
			} catch (e) {
				this.$refs.section.scrollTo(0, 0);
				console.log(e);
			}
		},
		checkAltn(type, event) {
			if (event.code.toLowerCase() === 'zzzz') {
				if (type === 'departure') {
					this.plan.otherInformation['DEP'] = "valami";
				} else if (type == 'destination') {
					this.plan.otherInformation['DEST'] = "valami";
				} else {
					if (this.plan.otherInformation['ALTN']) {
						this.plan.otherInformation['ALTN'].push("valami");
					} else {
						this.plan.otherInformation['ALTN'] = [];
						this.plan.otherInformation['ALTN'].push("valamid");
					}
				}
			} else {
				if (type === 'departure') {
					delete this.plan.otherInformation['DEP'];
				} else if (type == 'destination') {
					delete this.plan.otherInformation['DEST'];
				} else {
					delete this.plan.otherInformation['ALTN'];
				}
			}
			this.generateOtherInfo()
		},
		validation() {
			this.app.clearMessages();
			let errors = [];

			if (this.flightRuleRoute.length > 0 && !this.plan.route.includes(this.flightRuleRoute)) {
				errors.push(new ValidationErrorMessage('flightRuleRoute', 'flightPlan.error.flightRuleNotPresent'));
			}
			if (this.plan.registrationNumber.length < 1) {
				errors.push(new ValidationErrorMessage('registrationNumber', 'flightPlan.error.registrationNumber'));
			}
			if (this.plan.typeOfAircraft.length < 1) {
				errors.push(new ValidationErrorMessage('typeOfAircraft', 'flightPlan.error.typeOfAircraftRequired'));
			}
			if (this.plan.typeOfAircraft == 'ZZZZ' &&
				(!Object.keys(this.plan.otherInformation).includes('TYP')
					|| (Object.keys(this.plan.otherInformation).includes('TYP') && this.plan.otherInformation.TYP.length < 1)
				)) {
				errors.push(new ValidationErrorMessage('otherInformation', 'flightPlan.error.TYPIsRequired'));
			}
			if ((this.plan.turbulenceCategory || []).length < 1) {
				errors.push(new ValidationErrorMessage('turbulenceCategory', 'flightPlan.error.turbCatRequired'));
			}
			if ((this.plan.icaoEquipments || []).length < 1) {
				errors.push(new ValidationErrorMessage('icaoEquipments', 'flightPlan.error.icaoEquipmentsRequired'));
			}
			if ((this.plan.icaoSurveillances || []).length < 1) {
				errors.push(new ValidationErrorMessage('icaoSurveillances', 'flightPlan.error.icaoSurveillancesRequired'));
			}
			if (this.plan.departure.length < 1) {
				errors.push(new ValidationErrorMessage('departure', 'flightPlan.error.departureRequired'));
			}
			if (this.plan.departure == 'ZZZZ' &&
				(!Object.keys(this.plan.otherInformation).includes('DEP')
					|| (Object.keys(this.plan.otherInformation).includes('DEP') && this.plan.otherInformation.DEP.length < 1)
				)) {
				errors.push(new ValidationErrorMessage('departure', 'flightPlan.error.departureRequired'));
			}
			if ((this.plan.startTime || "").length < 1) {
				errors.push(new ValidationErrorMessage('startTime', 'flightPlan.error.startTimeRequired'));
			}
			if ((this.startDate || "").length < 1) {
				errors.push(new ValidationErrorMessage('startDate', 'flightPlan.error.startDateRequired'));
			}
			if ((this.plan.cruisingSpeed || "").length < 1) {
				errors.push(new ValidationErrorMessage('cruisingSpeed', 'flightPlan.error.cruisingSpeedRequired'));
			}
			if ((this.plan.level || "").length < 1) {
				errors.push(new ValidationErrorMessage('level', 'flightPlan.error.levelRequired'));
			}
			if (this.plan.levelUnit !== 'VFR' && this.plan.levelUnit !== 'M' && this.plan.level < 100) {
				errors.push(new ValidationErrorMessage('level', 'flightPlan.error.levelIsToLow'));
			}
			if (this.plan.levelUnit !== 'VFR' && this.plan.levelUnit === 'M' && this.plan.level < 10) {
				errors.push(new ValidationErrorMessage('level', 'flightPlan.error.levelIsToLow'));
			}
			if ((this.plan.route || "").length < 1) {
				errors.push(new ValidationErrorMessage('route', 'flightPlan.error.routeRequired'));
			}
			if ((this.plan.routeElements || "").length < 1) {
				errors.push(new ValidationErrorMessage('routeElements', 'flightPlan.error.routeRequired'));
			}

			if (this.plan.destination.length < 1) {
				errors.push(new ValidationErrorMessage('destination', 'flightPlan.error.destinationRequired'));
			}
			if (this.plan.destination == 'ZZZZ' &&
				(!Object.keys(this.plan.otherInformation).includes('DEST')
					|| (Object.keys(this.plan.otherInformation).includes('DEST') && this.plan.otherInformation.DEST.length < 1)
				)) {
				errors.push(new ValidationErrorMessage('destination', 'flightPlan.error.destinationRequired'));
			}

			if (this.plan.altnOne == 'ZZZZ' &&
				(!Object.keys(this.plan.otherInformation).includes('ALTN')
					|| (Object.keys(this.plan.otherInformation).includes('ALTN') && this.plan.otherInformation.ALTN.length < 1)
				)) {
				errors.push(new ValidationErrorMessage('altnOne', 'flightPlan.error.ALTNIsRequired'));
			}
			if (!Object.keys(this.plan.otherInformation).includes('OPR')
				|| (Object.keys(this.plan.otherInformation).includes('OPR') && this.plan.otherInformation.OPR.length < 1)) {
				errors.push(new ValidationErrorMessage('otherInformation', 'flightPlan.error.OPRIsRequired'));
			}
			if (!Object.keys(this.plan.otherInformation).includes('DOF')
				|| (Object.keys(this.plan.otherInformation).includes('DOF') && this.plan.otherInformation.DOF.length < 1)) {
				errors.push(new ValidationErrorMessage('otherInformation', 'flightPlan.error.DOFIsRequired'));
			}
			if ((this.plan.totalEET || "").length < 1) {
				errors.push(new ValidationErrorMessage('totalEET', 'flightPlan.error.totalEETRequired'));
			}
			if ((this.plan.endurance || "").length < 1) {
				errors.push(new ValidationErrorMessage('endurance', 'flightPlan.error.enduranceRequired'));
			}
			if ((this.plan.aircraftColor || "").length < 1) {
				errors.push(new ValidationErrorMessage('aircraftColor', 'flightPlan.error.aircraftColorRequired'));
			}
			if ((this.plan.pilotInCommand || "").length < 1) {
				errors.push(new ValidationErrorMessage('pilotInCommand', 'flightPlan.error.pilotInCommandRequired'));
			}
			if ((this.plan.pilotInCommandPhone || "").length < 1) {
				errors.push(new ValidationErrorMessage('pilotInCommandPhone', 'flightPlan.error.pilotInCommandPhoneRequired'));
			}
			if(!this.plan.personsOnBoard || this.plan.personsOnBoard < 1){
				errors.push(new ValidationErrorMessage('personsOnBoard', 'flightPlan.error.personsOnBoardRequired'));
			}


			errors.forEach(e => {
				this.app.addMessage(e)
			})

			if(errors.length > 0){
				throw new Error("Validation error");
			}


		},
		planStartTimeRecover() {
			if (this.plan.startTime && !this.plan.startTime.includes(':')) {
				this.plan.startTime = this.plan.startTime.substring(0, 2) + ':' + this.plan.startTime.substring(2, 4);
			}
		}
	},
	async created() {
		let mapObjects = await this.$rest.getAllObjects();
		this.airports = mapObjects.filter(object => object.type === "AIRPORT");
		this.airports.push({ code: 'ZZZZ' });
		this.planStartTimeRecover();
		let now = new Date()
		now.setHours(new Date().getUTCHours())
		now = now.getTime() + (35 * 60000);
		let utc = new Date(now);
		this.plan.startTime = String(utc.getHours()).padStart(2, '0') + ':' + String(utc.getMinutes()).padStart(2, '0');
	},
}
export class ValidationErrorMessage {
	component;
	messageKey;
	severity = "error";

	constructor(component, messageKey, severity = "error") {
		this.component = component;
		this.messageKey = messageKey;
		this.severity = severity;
	}
}
</script>

<style scoped>
.preview {
	border: 1px solid #e0e0e0;
	padding: 15px;
	margin: 20px;
}
</style>
