<template>
  <div class="p-3" >
    <div class="row">
      <div class="col-10 text-left">
        <h4>{{ $t('flightPlan.otherInformation') }}</h4>
      </div>
      <div class="col-2 text-right">
        <a v-on:click="$emit('close', {otherInformation:otherInformation})" href="javascript:void(0)"
           class="btn btn-warning text-center">
          <font-awesome-icon class="m-auto" icon="times"/>
        </a>
      </div>
    </div>
    <div class="row preview mt-3" style="height:400px; overflow-y:auto">
      <div class="col-12">
        <MultiSelect display="chip" style="width: 100%" v-model="selectedOtherOptions" :options="otherOptions" optionLabel="code"
                     :placeholder="$t('flightPlan.otherOptions')" @change="refreshOthers($event)"
        />
      </div>
      <div class="col-md-12 form-group" v-for="other in selectedOtherOptions" :key="other.code">
        <label>{{ other.code }}/</label>
        <input type="text" class="form-control" aria-describedby="otherHelp"
               v-model="otherInformation[other.code]" v-on:keyup="otherInformation[other.code] = otherInformation[other.code].toUpperCase()"/>
        <small id="otherHelp" class="form-text text-muted">{{ other.description }}</small>
      </div>
    </div>
    <div class="col-12 text-right mt-3">
      <a v-on:click="$emit('close',{otherInformation:otherInformation})" href="javascript:void(0)"
         class="btn btn-secondary text-center">
        <font-awesome-icon class="m-auto" icon="check-circle"/>
        {{ $t('routePlan.unitOk') }}
      </a>
    </div>
  </div>
</template>

<script>
import MultiSelect from "primevue/multiselect";

export default {
  name: "OtherChooserModal",
  props: ['otherInformation'],
  components: {
    MultiSelect
  },
  data() {
    return {
      selectedOtherOptions: [],
      otherOptions: [
        {code: "STS", description: "Indicators for Special Handling by ATS"},
        {code: "PBN", description: "RNAV and/or RNP Capability Indicators"},
        {code: "EUR", description: "Protected Status Indicator for IFPS"},
        {code: "NAV", description: "Nav. Equipment Data and GNSS Augmentation"},
        {code: "COM", description: "Comm. Equipment Not Specified in Field 10"},
        {code: "DAT", description: "Data Capabilities Not Specified in Field 10"},
        {code: "SUR", description: "Surveillance Capabilities Not Specified in Field 10"},
        {code: "REG", description: "Aircraft Registration if Different Than Field 7"},
        {code: "EET", description: "Estimated Enroute Time(s) in “HHMM” format to Significant Fixes or FIR Boundaries"},
        {code: "SEL", description: "SELCAL Code For Applicable Aircraft"},
        {code: "TYP", description: "Number and Type(s) of Aircraft if “ZZZZ” in Field 8"},
        {code: "CODE", description: "Aircraft Address Code in Six Hexadecimal Chars."},
        {code: "RVR", description: "Runway Visual Range Requirement in Metres"},
        {code: "DLE", description: "Enroute Delay or Holding Point with “HHMM” Time"},
        {code: "OPR", description: "ICAO Designator or Name of Aircraft Operator"},
        {code: "PER", description: "Aircraft Performance Category Where Applicable"},
        {
          code: "ALTN",
          description: "Name/Coords (ddmmNdddmmE) of Destination Alternate Aerodromes if “ZZZZ” in Field 16."
        },
        {code: "RALT", description: "Enroute Alternates"},
        {code: "TALT", description: "Take-off Alternates"},
        {code: "RIF", description: "Route Details to Revised Destination Aerodrome"},
        {code: "RMK", description: "Plain Language Remarks Where Necessary"},
        {code: "STAYINFO", description: "IFPS Indicators for Stay Activity"},
        {code: "RFP", description: "Replacement Flightplan Indicator for IFPS"},
        {code: "DEP", description: "Departure"},
        {code: "DEST", description: "Destination Aerodrome"},
        {code: "DOF", description: "Date of flight"},
      ]
    }
  },
  mounted() {
    this.selectedOtherOptions = this.otherOptions.filter(option => Object.keys(this.otherInformation).includes(option.code));
  },
  methods:{
    refreshOthers(){
     Object.keys(this.otherInformation).forEach(otherKey =>{
       if(!this.selectedOtherOptions.map(o => o.code).includes(otherKey)){
         delete this.otherInformation[otherKey];
       }
     })
    },
  }
}
</script>

<style scoped>

</style>
