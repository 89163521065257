<template>
  <div>
    <h2 class="header-common"> {{ $t('flightPlan.listHeader') }}</h2>
    <div class="row">
      <div class="col-md-12">
        <div id="tabs">
          <div class="tabs text-center">
            <a class="btn btn-primary tab-link-register" v-if="selectedPlan===null && activeTab ===2" v-on:click="create">
              <font-awesome-icon icon="plus"/>
              {{ $t('flightPlan.addNewPlan') }}
            </a>
          </div>

          <div class="content">
            <div v-if="activeTab === 1 && selectedPlan !== null" class="tabcontent hidden-wizard-container">
              <FlightPlanForm :msgs="msgs" :app="app" :plan="selectedPlan" @cancel="cancel" @success="success" @successUpdate="successUpdate"></FlightPlanForm>
            </div>
            <div v-if="activeTab ===2 && selectedPlan === null" class="tabcontent">
              <DataTable
                :value="flightPlans"
                :paginator="true"
                :rows="10"
                dataKey="id"
                responsiveLayout="scroll"
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                :rowsPerPageOptions="[10,20,50]"
                currentPageReportTemplate="{first}-{last} / {totalRecords}"
              >

                <Column :exportable="false" style="min-width:8rem">
                  <template #body="slotProps">
                    <Button class="btn btn-primary mr-2 mb-2  btn-action"
                            @click="edit(slotProps.data)"
                            style="text-align: center">
                      <font-awesome-icon style="margin: auto"
                                         icon="pencil-alt"/>
                    </Button>
                    <Button class="btn btn-warning mr-2 mb-2  btn-action"
                            @click="getPdf(slotProps.data)"
                            style="text-align: center">
                      <font-awesome-icon style="margin: auto" icon="file-pdf"/>
                    </Button>
                    <Button class="btn btn-danger mr-2 mb-2  btn-action"
                            @click="remove(slotProps.data)"
                            style="text-align: center">
                      <font-awesome-icon style="margin: auto" icon="trash"/>
                    </Button>
                  </template>
                </Column>
                <Column field="id" :header="this.$t('flightPlan.id')"></Column>
                <Column field="registrationNumber" :header="this.$t('planes.registrationNumber')"></Column>
                <Column field="route" :header="$t('flightPlan.route')"></Column>

              </DataTable>
              <div class="empty-table-msg-container" v-if="!flightPlans || flightPlans.length<1">
                {{ $t('routePlan.emptyList') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FlightPlanForm from "@/components/flightPlan/FlightPlanForm";
import BeforeEditModal from "@/components/flightPlan/flightPlanModal/BeforeEditModal";

export default {
  name: "FlightPlanView",
  components: {FlightPlanForm},
	props:{
		msgs: Object,
		app:Object
	},
  data() {
    return {
      activeTab: 2,

			/** @type {FlightPlan|null} */
      selectedPlan: null,

			/** @type {User|null} */
      user: null,

			/** @type {FlightPlan[]} */
      flightPlans:[]
    };
  },
	mounted() {
		this.loadFlightPlans();
		this.loadUser();
  },
  methods:{
		async loadFlightPlans(){
			const flightPlans = await this.$rest.getFlightPlansForUser();
			if(!flightPlans) return;
			this.flightPlans = flightPlans;
		},
		async loadUser() {
			const user = await this.$rest.getLoggedInUser();
			if(!user) return;
			this.user = user;
		},
    create(){
      if(this.selectedPlan == null){
        this.selectedPlan = this.initPlan();
      }
      this.showBeforeEditModal();
    },
    edit(plan){
      this.selectedPlan = plan;
      this.activeTab = 1;
    },
    async remove(plan){
			const result = await this.$rest.deleteFlightPlan(plan.id);
			if(result) {
				this.flightPlans = this.flightPlans.filter((p) => p.id !== plan.id);
			}
    },
		/** @param {FlightPlan} plan */
    getPdf(plan){
      let loggedInUserToken = this.$store.getters.loggedInUserToken;
      if (!loggedInUserToken) return;
			let a = document.createElement('a');

			window.newEvent("PRINT_FLIGHT_PLAN", {
				id: `${plan.id}`,
			})

			a.href = `${this.$rest.baseUrl}/flight-plan/${plan.id}/pdf?token=${loggedInUserToken}`;
			a.target = '_blank';
			document.body.appendChild(a);
			a.click();
    },
    cancel(){
      this.selectedPlan = null;
      this.activeTab = 2;
    },
    success(plan){
      this.selectedPlan = null;
      this.activeTab = 2;
      this.flightPlans.push(plan);
      this.flightPlans.sort((a,b) => a.id>b.id?-1:1);
      this.getPdf(plan);
    },
    successUpdate(plan){
      this.selectedPlan = null;
      this.activeTab = 2;
      this.flightPlans = this.flightPlans.filter((p) => p.id !== plan.id);
      this.flightPlans.push(plan);
      this.flightPlans.sort((a,b) => a.id>b.id?-1:1);
      this.getPdf(plan);
    },
		/** @returns {FlightPlan} */
    initPlan(){
			return {
				registrationNumber: "",
				plane: null,
				routePlan: null,
				number: 1,
				flightRule: "V",
				typeOfFlight: "G",
				typeOfAircraft: "",
				turbulenceCategory: "",
				equipments: "",
				icaoEquipments: [],
				icaoSurveillances: [],
				departure: "",
				startTime: "",
				speedUnit: "N",
				departureTime: "",
				travelSpeed: 0,
				cruisingSpeed: "0",
				levelUnit: "A",
				travelHeight: 0,
				level: "0",
				routeElements: [],
				route: "",
				destination: "",
				altnOne: "",
				altnTwo: "",
				totalEET: "",
				endurance: "",
				personsOnBoard: 0,
				emergencyRadios: [],
				survivalEquipments: [],
				jackets: [],
				aircraftColor: "",
				remarks: "",
				other: "",
				otherInformation: { OPR: "" },
				pilotInCommand: (this.user ? this.user.name : ""),
				pilotInCommandPhone: (this.user ? this.user.phone : ""),
			};
    },
    showBeforeEditModal() {
      this.$modal.show(BeforeEditModal, {
        plan: this.selectedPlan
      }, {
        clickToClose: false,
        adaptive: true,
      }, {
        'before-close': this.beforeCloseBeforeEditModal
      });
    },
    beforeCloseBeforeEditModal(event) {
      this.activeTab = 1;
    },

  }
}
</script>

<style scoped>
.btn-action {
  font-size: 12px !important;
  font-weight: normal !important;
}
</style>
